import React from "react"
import Layout from "../../components/layout-materials"
import { Link } from "gatsby"

export default () => (
  <Layout title="Violin Teaching Products">
    <h2>Violin Teaching Products</h2>
    <div className="clear-block">
      <div id="node-64" className="node">
        <div className="content clear-block">
          <p><img src="/images/Cordell-for-web.jpg" className="float-left" width="300"
                  height="225" alt=""/>Violin has been taught to young children for years by the Suzuki method with great
            success, except that when the child came to trying to note read, it was even more frustrating. Our teaching
            studio has experienced this more than once. Reading standard music notation can be very frustrating,
            especially when it slows your progress down to the pace of a turtle, when you are used to running like a
            rabbit. Sometimes a student will quit before they have a chance to succeed.<br/>
            Many children can be trained to play a piece by ear and do quite well. A well trained ear is very important
            to successfully play the violin as there are no frets to guide the fingers. Placement tape is usually used
            in the beginning, which is of great value, but the ear must learn the tone being played so it is recognized
            and played correctly when the tape is removed. However, learning pieces by ear for most individual tends to
            limit the number of songs played. Eventually notes reading must be mastered. </p>
          <p>Noteimals has spent 4 years developing "<Link to="/books/Violin-Note-Reading-for-Children">Note Reading for
            the Violin</Link>". It has been carefully researched, tested and revised in our effort to bring you a new and
            improved method of teaching your young students how to play the violin. </p>

          <br/>
          <h3>Teaching materials for the Violin</h3>
          <br/>
          <p><img src="/images/Matt-Web_1.jpg" className="float-right" width="300" height="225" alt=""/>"<Link
            to="/books/Violin-Note-Reading-for-Children">Note Reading for the Violin</Link>" teaches both note reading
            and timing. The G below middle C is the lowest note this instrument can play, so our Timing Book, and the
            songs in our Fun Books will not work for this instrument, thus a few fun songs are included in the Violin
            Note Reading book along with the note reading practice pieces. Like most beginning violin books, the lessons
            start with the open notes and move on the notes on each string. There are pages In the front of the book
            with the Animal Note Word Clues and an introduction to the Note Family. A Fake Song Book of Fun Songs is in
            the works, which can be used to teach chording on the piano, as well as providing songs for the guitar and
            the violin. It should be out by the first of the New Year.</p>

          <br/>
          <h3>Flash Cards for the Violin</h3>
          <br/>

          <p>Since violin music is usually written in the treble clef, we have created and made available, "<Link
            to="/flash-cards/Animal-Note-Standard-Note-Flash-Card-Set-Student-Size-Treble-or-G-Clef">Animal
            Note/standard note Flash Card Set</Link>" and the "<Link
            to="/flash-cards/Classroom-Size-Animal-Note-Standard-Note-Flash-Card-Set-Treble-or-G-Clef">Classroom Size
            Treble Clef Animal Note/standard note /Flash/Card Set</Link>". By teaching the word clue associated with each
            animal and then showing the standard note and repeating the clue your student will quickly learn and
            remember each note. The flash cards and word clues are a vital part of note reading training and should be
            used on a regular bases.</p>
        </div>

        <div className="clear-block">
          <div className="meta">
          </div>

        </div>

      </div>
    </div>
  </Layout>
)
